import { toast, Id, ToastOptions, Slide } from 'react-toastify'
import { Flex, Text, Icon, Button, Image } from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons, images } from 'theme'
import 'react-toastify/dist/ReactToastify.css'
import './toast.css'

type ToastIconType = 'info' | 'check' | 'error'

/**
 * get toast icon
 * @param type
 * @returns
 */
const getCustomIcon = (type?: ToastIconType) => {
  switch (type) {
    case 'info':
      return <Icon as={icons.info} color="textRegular" h={20} w={20} p={0} mr={12} />
    case 'check':
      return <Icon as={icons.check} color="textRegular" h={20} w={20} p={0} mr={12} />
    case 'error':
      return <Image src={images.uploadFailed} alt="failed" h={20} w={20} p={0} mr={12} />
    default:
      return undefined
  }
}

/**
 * custom toast with action button
 * @param param
 * @returns
 */
const CustomActionToast = ({
  message,
  onUndo,
  onShow,
}: {
  message: string
  onUndo?: () => void
  onShow?: () => void
}) => (
  <Flex flexDir="row" align="center" justifyContent="space-between" w="full" h="full">
    <Flex flexDir="row" align="center" justifyContent="space-between">
      <Text>{message}</Text>
    </Flex>
    {onUndo && (
      <Button fontSize={14} onClick={onUndo} mr={8}>
        Undo
      </Button>
    )}
    {onShow && (
      <Button fontSize={14} onClick={onShow} mr={8}>
        View
      </Button>
    )}
  </Flex>
)

/**
 * custom toast close button
 * @param param
 * @returns
 */
const closeButton =
  ({ onClose }) =>
  ({ closeToast }) =>
    (
      <IconButton
        ariaLabel="Close"
        icon={icons.close}
        iconSize={{ width: 16, height: 16 }}
        onClick={() => {
          closeToast()
          onClose && onClose()
        }}
      />
    )

/**
 * present toast if active toast does not exists
 * update toast if active toast presenting
 * @param
 * @returns
 */
const show = ({
  id,
  icon = 'info',
  message,
  onUndo,
  onShow,
  onClose,
  option = {},
}: {
  id?: Id
  message: string
  icon?: ToastIconType
  onUndo?: () => void
  onShow?: () => void
  onClose?: () => void
  option?: ToastOptions
}) => {
  // update toast
  if (id && toast.isActive(id)) {
    return toast.update(id, {
      render: onUndo || onShow ? <CustomActionToast message={message} onUndo={onUndo} onShow={onShow} /> : message,
      icon: () => getCustomIcon(icon),
      closeButton: closeButton({ onClose }),
      ...option,
    })
  }
  // present toast
  return toast(onUndo || onShow ? <CustomActionToast message={message} onUndo={onUndo} onShow={onShow} /> : message, {
    icon: () => getCustomIcon(icon),
    transition: Slide,
    position: 'bottom-center',
    theme: 'dark',
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    toastId: id,
    closeButton: closeButton({ onClose }),
    ...option,
  })
}

/**
 * dismiss toast
 * if id specified, it close a toast
 * if id does not specified, close all toast
 * @param id
 * @returns
 */
const dismiss = (id?: Id) => toast.dismiss(id)

/**
 * max 4 toasts appear in the screen
 * clear all queued toasts if needed
 * @returns
 */
const clearQueue = () => toast.clearWaitingQueue()

export default {
  show,
  dismiss,
  clearQueue,
}
