import { isEmpty, partition } from 'lodash'
import { useMutation } from '@apollo/client'
import * as path from 'path-browserify'
import JSZip from 'jszip'
import { useAppSlice, useNodeSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'
import { GET_NODE, GET_BLOCKS, CREATE_BLOCK } from 'features/graphql'
import { uploadFile, downloadFile, downloadText } from 'helpers/storage'
import { useFlow } from 'features/workflow/hooks'
import { client as apollo } from 'helpers/apollo'
import { getVideoCover } from 'helpers/utils'
import { nodeType, nodeState, blockType } from 'helpers/constant'
import { Block, Flow, Maybe, NodeUpdateInput, Scalars } from 'types/graphqlSchema'
import { Toast } from 'components'

interface IParams extends Omit<NodeUpdateInput, 'id'> {
  id?: Scalars['ID']
}

type FolderUploadStatus = {
  index: number
  name: string
  files: {
    filePath: string
    progress: number
  }[]
}

export default function useStorage() {
  const { dispatch, setIsLoading } = useAppSlice()
  const { createUploadFolder } = useNodeSlice()
  const { currentProject } = useProjectSlice()
  const { currentBox } = useBoxSlice()
  const { createNewTip, updateTip, setTipUpdating } = useTipSlice()
  const { fetchAncestorFlows } = useFlow()

  const uploadErrors: any = []

  const [mutateCreateBlock] = useMutation(CREATE_BLOCK)

  const generateCoverImage = async (file: File) => {
    const fileExt = file?.type ? file.type : file?.name.split('.').pop() || ''

    let fileToUpload

    if (fileExt.includes('image') && !fileExt.includes('svg') && !fileExt.includes('bmp')) {
      fileToUpload = file
    } else if (fileExt.includes('video')) {
      // extract thumbnail from video file
      Toast.show({
        message: `Generating cover image for ${file.name}`,
        option: {
          type: 'info',
          theme: 'colored',
          autoClose: 6000,
        },
      })
      fileToUpload = await getVideoCover(file, 0.0)
    } else {
      // no image file for cover image
      return null
    }

    if (!fileToUpload) {
      throw new Error('error uploading cover image file')
    }
    // create a new file with the new name
    const renamedFile = new File([fileToUpload], `cover_image_${file.name}`, { type: fileToUpload.type })

    const uploadResponse = await uploadFile(renamedFile)
    return uploadResponse.fileUrl
  }

  const uploadBlockFile = async (
    file: File,
    { nodeId, flowId, order }: any,
    mutateAddBlock: any,
    setProgress: (perc: number) => void = () => {},
  ) => {
    try {
      if (!(nodeId || flowId)) {
        throw new Error('error uploading block file')
      }

      const uploadResponse = await uploadFile(file, setProgress)

      if (!uploadResponse.fileUrl) {
        throw new Error('error uploading file')
      }
      dispatch(setTipUpdating(true))
      const fileMime = file.type || file.name.split('.').pop() || ''
      let type = blockType.file
      if (fileMime.includes('image')) {
        type = blockType.image
      } else if (fileMime.includes('audio')) {
        type = blockType.audio
      } else if (fileMime.includes('video')) {
        type = blockType.video
      }

      const blockInput = {
        content: uploadResponse.fileUrl,
        name: file.name || '',
        preview: uploadResponse.convertedFileUrl || null,
        extension: file.name.split('.').pop() || '',
        order: order || 0,
        size: file.size,
        type,
      }
      let input
      if (nodeId) {
        input = { ...blockInput, nodeId }
      } else if (flowId) {
        input = { ...blockInput, flowId }
      }

      await mutateAddBlock({ variables: { input } }) // add block to db
    } catch (er: any) {
      console.log('error uploading block file', er)
      throw new Error(er)
    }
  }

  const uploadTipFile = async (file, params, setProgress) => {
    try {
      const { organizationId, ownerId, projectId, parentId, updatedById } = params
      const tipInput = {
        name: file?.name || 'untitled',
        state: nodeState.pending,
        type: nodeType.tip,
        projectId,
        parentId,
        updatedById,
        organizationId,
        setCurrentTip: params?.setCurrentTip || false, // TODO: remove later
      }

      const {
        payload: { createNode },
      } = await dispatch(createNewTip(tipInput))

      const blockParams = {
        nodeId: createNode.id,
        ownerId,
        organizationId,
        order: 0,
      }

      // Set cover image for tip
      const coverImage = await generateCoverImage(file)
      await uploadBlockFile(file, blockParams, mutateCreateBlock, setProgress)

      const result = await dispatch(
        updateTip({
          id: createNode.id,
          coverImage,
          state: nodeState.active,
          setCurrentTip: params?.setCurrentTip || false, // TODO: remove later
        }),
      )

      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  const newlyCreatedNodeIds: any = []

  const createFolderTip = async (
    file: any,
    tipInfo: {
      tipId: string
      path: string
      s3Key: string
      name: string
      ownerId: string
      organizationId: string
    },
    setProgress: (perc: number) => void = () => {},
  ) => {
    try {
      if (!file.type && !file.path.includes('rtf')) return

      // create node block and upload block file
      const blockParams = {
        nodeId: tipInfo.tipId,
        organizationId: tipInfo.organizationId,
        ownerId: tipInfo.ownerId,
        order: 0,
      }
      await uploadBlockFile(file, blockParams, mutateCreateBlock, setProgress)

      const coverImage = await generateCoverImage(file)
      await dispatch(
        updateTip({
          id: tipInfo.tipId,
          coverImage,
          // disableNotification: true, // TODO: need to refactor this
        }),
      )

      newlyCreatedNodeIds.push(tipInfo.tipId)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  // Show a progress toast for uploads
  const showProgressToast = (id: string, name: string, progress: number) => {
    Toast.show({
      id,
      message: `${name}: ${Math.trunc(progress ?? 0)}%`,
      option: {
        type: 'info',
        theme: 'colored',
        autoClose: progress === 100 ? 3000 : false,
      },
    })
  }

  // Partition files into flat files and folder files
  const partitionFiles = (files: any[]) => {
    const folders: FolderUploadStatus[] = []
    const [flatFiles, folderFiles] = partition(files, (file) => {
      const fullDir = path.dirname(file.path || '')
      const dirArray = fullDir.split(path.sep).slice(1)

      if (isEmpty(dirArray) || (dirArray.length === 1 && dirArray[0] === '')) {
        return true
      }

      const folderName = dirArray[0]
      if (typeof folderName === 'string') {
        const i = folders.findIndex(({ name }) => name === folderName)
        if (i === -1) {
          folders.push({
            index: folders.length,
            name: folderName,
            files: [{ filePath: file.path, progress: 0 }],
          })
        } else {
          folders[i].files = [...folders[i].files, { filePath: file.path, progress: 0 }]
        }
      }
      return false
    })

    return { flatFiles, folderFiles, folders }
  }

  // Upload flat files
  const uploadFlatFiles = async (files: any[], params: IParams, updatedTips: any[], uploadErrors: any[]) => {
    for (const file of files) {
      const nameArray = file.name.split('.')
      const extension = nameArray.pop()

      try {
        const res = await uploadTipFile(file, { ...params, extension }, (progress) =>
          showProgressToast(file.name, file.name, progress),
        )
        updatedTips.push(res)
      } catch (error: any) {
        console.log('uploading error', error)
        uploadErrors.push({ error, file })
      }
    }
  }

  // Upload folder files
  const uploadFolderFiles = async (
    folderFiles: any[],
    params: IParams,
    folders: FolderUploadStatus[],
    newTipsInfo: any[],
    uploadErrors: any[],
  ) => {
    for (const file of folderFiles) {
      const tipInfo = newTipsInfo.find((tip) => tip.path === file.path)
      if (!tipInfo) continue

      const targetFolder = folders.find(({ files }) => files.some(({ filePath }) => file.path === filePath))
      const info = { ...params, ...tipInfo }

      try {
        await createFolderTip(file, info, (progress) => {
          if (targetFolder) {
            const fileIndex = targetFolder.files.findIndex(({ filePath }) => file.path === filePath)
            targetFolder.files[fileIndex].progress = progress
            const progressAll = targetFolder.files.reduce((sum, file) => sum + file.progress, 0)
            const overallProgress = Math.trunc(progressAll / targetFolder.files.length)
            showProgressToast(targetFolder.name, `Folder ${targetFolder.name}`, overallProgress)
          }
        })
      } catch (error: any) {
        uploadErrors.push({ error, file })
      }
    }
  }

  // Handle file and folder uploads
  const onDropUpload = async (files: any[], params: IParams) => {
    const updatedTips: any[] = []
    const uploadErrors: any[] = []
    const newlyCreatedNodeIds: any[] = []

    try {
      const { flatFiles, folderFiles, folders } = partitionFiles(files)

      await uploadFlatFiles(flatFiles, params, updatedTips, uploadErrors)

      if (folderFiles.length > 0) {
        const nodeFiles = folderFiles.map((file) => ({
          path: file.path || '',
          name: file.name,
        }))
        const newParams = { parentId: currentBox ? currentBox.id : currentProject?.id, files: nodeFiles }
        const { payload } = await dispatch(createUploadFolder(newParams))

        if (!payload) throw new Error('upload failed')

        newlyCreatedNodeIds.push(...payload.boxIds)
        await uploadFolderFiles(folderFiles, params, folders, payload.uploadedTipInfos, uploadErrors)
      }

      updatedTips.forEach(
        ({
          payload: {
            updateNode: { id },
          },
        }) => newlyCreatedNodeIds.push(id),
      )

      return { newlyCreatedNodeIds, error: uploadErrors }
    } catch (error: any) {
      return { newlyCreatedNodeIds, error: uploadErrors }
    } finally {
      Toast.clearQueue()
      Toast.dismiss()
    }
  }

  // download file to computer
  const downloadBlob = (blob: any, filename: string) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename || 'download'
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener('click', clickHandler)
      }, 150)
    }
    a.addEventListener('click', clickHandler, false)
    a.click()
    return a
  }

  const getFilesInDocument = async (tip: ITip, parentDirectory: string, isPublic: boolean) => {
    // get all blocks in the tip
    const { data, error } = await apollo.query({
      query: GET_BLOCKS,
      variables: { nodeId: tip.id },
    })
    if (error) throw new Error(error.message)
    const files: { path: string; content: any }[] = []
    if (data) {
      if (data.blocks.length === 1 && data.blocks[0].type === 'TEXT') {
        // single text block
        files.push({
          path: path.join(parentDirectory, `${tip.name}.html`),
          content: new Blob([data.blocks[0].content], { type: 'text/plain;charset=utf-8' }),
        })
      } else if (data.blocks.length === 1 && data.blocks[0].type !== 'TEXT' && data.blocks[0].name === tip.name) {
        // single file block and file block name is the same as document name
        const result: any = await downloadFile(data.blocks[0].content)
        files.push({
          path: path.join(parentDirectory, tip.name),
          content: result.data,
        })
      } else if (
        data.blocks.length === 2 &&
        data.blocks[0].type === 'TEXT' &&
        data.blocks[0].content === '' &&
        data.blocks[1].name === tip.name
      ) {
        // user creates a document with a single file and name the document the same as the block file
        // TODO: if the text block is empty, we shouldn't save it
        const result: any = await downloadFile(data.blocks[1].content)
        files.push({
          path: path.join(parentDirectory, tip.name),
          content: result.data,
        })
      } else {
        // multiple blocks or file block name is not the same as tip name
        await Promise.all(
          data.blocks.map(async (block: IBlock) => {
            const directory = path.join(parentDirectory, tip.name)
            if (block.type === blockType.text) {
              if (!isEmpty(block.content)) {
                // if block type is text, create html file
                files.push({
                  path: path.join(directory, `${tip.name}.html`),
                  content: new Blob([block.content], { type: 'text/plain;charset=utf-8' }),
                })
              }
            } else {
              // if block type is file, download
              const result: any = await downloadFile(block.content)
              const blockName = block.name || `${block.order}_${tip.name}.${block.extension}`
              files.push({
                path: path.join(directory, blockName),
                content: result.data,
              })
            }
          }),
        )
      }
    }
    return files
  }

  const downloadFlow = async (flow: Flow) => {
    try {
      if (flow && flow.blocks) {
        const downloadingBlocks = flow.blocks
        if (flow.parentId) {
          // download all ancestor's file blocks
          const ancestorFileBlocks: Maybe<Block>[] = []
          const ancestors: Flow[] = await fetchAncestorFlows(flow.parentId)
          for (const ancestor of ancestors) {
            if (ancestor && ancestor.blocks) {
              //! filter out text blocks from acestor.blocks
              ancestorFileBlocks.push(...ancestor.blocks.filter((block) => block?.type !== blockType.text))
            }
          }
          if (ancestorFileBlocks.length > 0) {
            downloadingBlocks.push(...ancestorFileBlocks)
          }
        }
        downloadItemBlocks(flow.name ?? 'Untitled', <Block[]>downloadingBlocks)
      }
    } catch (error) {
      Toast.show({
        icon: 'error',
        message: `Something went wrong with downloading ${flow ? flow.name : 'item'}`,
      })
    }
  }

  const downloadDocument = async (tip: ITip, isPublic = false) => {
    // get all blocks in the tip
    const { data, error } = await apollo.query({
      query: GET_BLOCKS,
      variables: {
        nodeId: tip.id,
      },
    })
    if (error) throw new Error(error.message)
    if (data && data.blocks) {
      downloadItemBlocks(tip.name, data.blocks)
    }
  }

  const downloadItemBlocks = async (itemName: string, blocks: Block[]) => {
    if (blocks.length === 1 && blocks[0].type === 'TEXT') {
      // download a single text block
      downloadText(`${itemName}.html`, blocks[0].content)
    } else if (blocks.length === 1 && blocks[0].type !== 'TEXT' && blocks[0].name === itemName) {
      // download a single file block and if it has the same name as the document's
      downloadBlockFile(blocks[0])
    } else if (blocks.length === 2 && blocks[0].type === 'TEXT' && blocks[0].content === '') {
      downloadBlockFile(blocks[1])
    } else {
      // zip them up in 1 zip file
      const zip = new JSZip()
      let hasDownloadedTextBlock = false
      await Promise.all(
        blocks.map(async (block: Block) => {
          if (block.type === blockType.text) {
            if (!isEmpty(block.content) && !hasDownloadedTextBlock) {
              // if block type is text, create html file
              zip.file(`${itemName}.html`, block.content ?? '')
              hasDownloadedTextBlock = true
            }
          } else {
            // if block type is file, download
            const result = await downloadFile(block.content ?? '')
            const blockName = block.name || `${block.order}_${itemName}.${block.extension}`
            zip.file(blockName, result.data)
          }
        }),
      )
      // add files to zip file named as tip name and download to computer
      const content = await zip.generateAsync({ type: 'blob' })
      downloadBlob(content, `${itemName}.zip`)
    }
  }

  // download folder as zip file to computer
  const downloadFolder = async (baseObj: IProject | IBox, isPublic = false) => {
    const zip = new JSZip()
    const files: { path: string; content: any }[] = []
    const getFilesRecursively = async (obj: any, directory: string) => {
      // download all children tips at this box level and put in files array
      await Promise.all(
        obj.children
          .filter((child: any) => child.type === 'TIP')
          .map(async (childTip: any) => {
            if (!childTip) return
            try {
              const tipFiles = await getFilesInDocument(childTip, directory, isPublic)
              files.push(...tipFiles)
            } catch (error: any) {
              throw new Error(error.message)
            }
          }),
      )
      // get all children boxes at this box level and create zip folder
      // then get files recursively on each box
      await Promise.all(
        obj.children
          .filter((c: INode) => c.type === 'BOX')
          .map(async (childBox: IBox) => {
            if (!childBox) return
            const {
              data: { node },
            } = await apollo.query({
              query: GET_NODE,
              variables: {
                id: childBox.id,
              },
            })
            const newDir = path.join(directory, node.name)
            zip.folder(newDir) // this will create empty box in zip file
            try {
              await getFilesRecursively(node, newDir)
            } catch (catchError: any) {
              throw new Error(catchError.message)
            }
          }),
      )
    }

    // get files recursively and add it's path and content to files array
    try {
      await getFilesRecursively(baseObj, '')
    } catch (error: any) {
      throw new Error(error.message)
    }
    // add files to zip file named as base folder and download to computer
    files.map((file) => zip.file(file.path, file.content))
    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      downloadBlob(content, `${baseObj.name}.zip`)
    })
  }

  const downloadBlockFile = async (block: Block) => {
    try {
      if (!block?.content) {
        throw new Error('No content in block')
      }
      const result = await downloadFile(block.content)
      await downloadBlob(result.data, block.name || 'untitled')
      dispatch(setIsLoading(false))
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const download = async (node) => {
    dispatch(setIsLoading(true))
    if (node.type === nodeType.tip) {
      try {
        await downloadDocument(node)
      } catch (error) {
        console.log(error.message)
        throw new Error(error.message)
      }
    } else if (node.type === nodeType.project || node.type === nodeType.box) {
      try {
        await downloadFolder(node)
      } catch (error) {
        throw new Error(error.message)
      }
    }
    dispatch(setIsLoading(false))

    return {}
  }

  return {
    onDropUpload,
    uploadTipFile,
    uploadBlockFile,
    downloadBlockFile,
    downloadFlow,
    download,
    partitionFiles,
  }
}
