import React, { useState, useEffect } from 'react'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useBreakpointValue,
  Flex,
  ModalOverlay,
} from '@chakra-ui/react'
import { useAppSlice } from 'features/redux'
import { AnimatePresence, motion } from 'framer-motion'
import { useUserSettingsSlice } from '../userSettings.slice'
import PasswordSettings from './PasswordSettings'
import { icons } from 'theme'
import AvatarSettings from './AvatarSettings'
import SMSActivation from './SMSActivation'
import { UserSettingsTabWrapper } from './UserSettingsTabWrapper'
import DeleteAccountSettings from './DeleteAccountSettings'
import CustomFade from 'components/elements/Transition'
import { Toast } from 'components'

type UserSettingsProps = {
  isOpen: boolean
  close: (close: boolean) => void
}

const UserSettingsModal = ({ isOpen, close }: UserSettingsProps) => {
  const isMobile = useBreakpointValue({ xs: true, md: false })
  const [containerHeight, setContainerHeight] = useState(728)
  const { user, updateMe, dispatch } = useAppSlice()
  const [showImgCrop, setShowImgCrop] = useState<boolean>(false)

  const {
    userFirstName,
    userLastName,
    showChangePasswordPanel,
    setShowChangePasswordPanel,
    showAvatarChangePanel,
    setShowAvatarChangePanel,
    showModalBody,
    setShowModalBody,
    showSMSActivation,
    setShowSMSActivation,
    showDeleteSettingsPanel,
    setShowDeleteSettingsPanel,
    setTabIndex,
    setUserFirstName,
    setUserLastName,
  } = useUserSettingsSlice()

  const handleSave = async () => {
    const input: IUserUpdateInput = {
      firstName: userFirstName,
      lastName: userLastName,
      avatar: user.avatar,
    }
    try {
      dispatch(updateMe(input))
      dispatch(setUserFirstName(''))
      dispatch(setUserLastName(''))
      Toast.show({
        icon: 'check',
        message: 'Account updated successfully',
      })
      onClose()
    } catch (error) {
      Toast.show({
        icon: 'error',
        message: 'Something went wrong, please try again later',
      })
    }
  }

  const onBack = () => {
    dispatch(setShowChangePasswordPanel(false))
    dispatch(setShowAvatarChangePanel(false))
    dispatch(setShowSMSActivation(false))
    dispatch(setShowDeleteSettingsPanel(false))
    dispatch(setShowModalBody(true))
  }

  const onClose = () => {
    close(true)
    dispatch(setShowChangePasswordPanel(false))
    dispatch(setShowAvatarChangePanel(false))
    dispatch(setShowSMSActivation(false))
    dispatch(setShowDeleteSettingsPanel(false))
    dispatch(setShowModalBody(true))
    dispatch(setTabIndex(0))
    setShowImgCrop(false)
  }

  /*
   * Handle container height
   */
  useEffect(() => {
    if (showChangePasswordPanel) {
      setContainerHeight(560)
      return
    }
    if (showAvatarChangePanel || showSMSActivation) {
      setContainerHeight(641)
      return
    }
    if (showDeleteSettingsPanel) {
      setContainerHeight(280)
      return
    }
    setContainerHeight(705)
  }, [showChangePasswordPanel, showAvatarChangePanel, showModalBody])

  return (
    <Modal isOpen={isOpen} onClose={onClose} borderRadius={[0, null, null, 16]}>
      <ModalOverlay />
      <ModalContent
        maxW={'636px'}
        w={'636px'}
        bg={'textHighlight'}
        color={'textBlack'}
        fontFamily={'Inter'}
        px={'1rem'}
      >
        <AnimatePresence>
          <motion.div
            layout
            key="shareModal"
            aria-label="Share"
            initial={{ opacity: 0, height: 200 }}
            animate={{
              height: containerHeight,
              opacity: 1,
            }}
            transition={{
              type: 'ease',
              duration: 0.6,
              opacity: { delay: 0.6 },
            }}
            exit={{
              opacity: 0,
            }}
            style={{
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              gap: isMobile ? '.5rem' : '1.25rem',
              width: '100%',
              fontSize: isMobile ? '12px' : '14px',
              overflow: 'hidden',
            }}
          >
            <ModalHeader
              borderRadius={[0, null, null, 16]}
              textAlign={'left'}
              pb={'0px'}
              px={showModalBody ? '1.5rem' : '0.25rem'}
              pt={showModalBody ? '1rem' : '0'}
            >
              {showModalBody &&
                (!showAvatarChangePanel || !showChangePasswordPanel || !showSMSActivation || !showImgCrop) && (
                  <CustomFade>
                    <Heading
                      color={'textBlack'}
                      fontWeight={'500'}
                      fontSize={'24px'}
                      paddingTop={'0.75rem'}
                      width={!isMobile ? 'full' : '85%'}
                    >
                      Account Settings
                    </Heading>
                  </CustomFade>
                )}
              {(showChangePasswordPanel || showAvatarChangePanel || showSMSActivation || showDeleteSettingsPanel) && (
                <CustomFade>
                  <Flex
                    alignItems={'center'}
                    color={'accent'}
                    gap={'0.5rem'}
                    mt={'18px'}
                    onClick={() => onBack()}
                    cursor={'pointer'}
                    fontSize={'16px'}
                  >
                    <icons.FaChevronLeft />
                    Back
                  </Flex>
                </CustomFade>
              )}
              <ModalCloseButton color={'black'} />
            </ModalHeader>
            <ModalBody py={0}>
              {showModalBody && (
                <CustomFade>
                  <UserSettingsTabWrapper />
                </CustomFade>
              )}
              {/* Edit password panel */}
              {showChangePasswordPanel && <PasswordSettings />}
              {/* Edit Avatar panel */}
              {showAvatarChangePanel && <AvatarSettings showImgCrop={showImgCrop} setShowImgCrop={setShowImgCrop} />}
              {/* SMS Activation */}
              {showSMSActivation && <SMSActivation />}
              {/* Delete Account */}
              {showDeleteSettingsPanel && <DeleteAccountSettings />}
            </ModalBody>
            {showModalBody && (
              <CustomFade>
                <ModalFooter pt={0}>
                  <Button
                    variant="defaultButton"
                    mb={'14px'}
                    mt={'7px'}
                    onClick={handleSave}
                    isDisabled={
                      (userFirstName?.length === 0 && userLastName?.length === 0) ||
                      (userFirstName === user.firstName && userLastName === user.lastName)
                    }
                  >
                    Save and Close
                  </Button>
                </ModalFooter>
              </CustomFade>
            )}
          </motion.div>
        </AnimatePresence>
      </ModalContent>
    </Modal>
  )
}

export default UserSettingsModal
